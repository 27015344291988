<template >
  <div >
    <h5 v-if="createAfterTableData.length !== 0" >
      {{ $t('key1003870') }}{{ createAfterTableData.length }}{{ $t('key1003871') }} </h5 >
    <Table border :columns="columns" :data="createAfterTableData" ></Table >
  </div >
</template >
<script >
import { alias47916751af154eb5b47cd5de9d34633d } from '@/customFolder/customVueAlias.js';

export default {
  props: ['data', 'createAfterTableData'],
  data () {
    return {
      columns: [
        {
          type: 'index',
          width: 60,
          align: 'center'
        }, {
          title: alias47916751af154eb5b47cd5de9d34633d.t('key1003872'),
          key: 'pickingGoodsNo'
        }, {
          title: alias47916751af154eb5b47cd5de9d34633d.t('key1002590'),
          key: 'type'
        }, {
          title: alias47916751af154eb5b47cd5de9d34633d.t('key1002541'),
          key: 'warehouseName'
        }
      ]
    };
  },
  created () {},
  computed: {},
  destroyed () {
    this.$parent.$parent.createAfterTableData = [];
  }
};
</script >

<style ></style >
