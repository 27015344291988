<template>
  <div class="container_box details_box_styles">
    <!--合并规则-->
    <div class="basic_information_box">
      <div class="tabs_box">
        <div :style="singleRuleStyle" @click.self="ruleClick(0)" class="fl">
          <span class="font-size-16" @click.self="ruleClick(0)">{{ $t('key1006114') }}</span>
        </div>
        <div :style="mulRuleStyle" @click.self="ruleClick(1)" class="fl">
          <span class="font-size-16" @click.self="ruleClick(1)">{{ $t('key1006115') }}</span>
        </div>
        <!--保存按钮-->
        <Button type="primary" @click="saveRule" class="save_btn_style"
          :disabled="!getPermission('wmsPickingGoods_createTask')">{{ clickRuleType === 0 ? $t('key1006112') : $t('key1006113') }} </Button>
      </div>
      <Card :bordered="false" dis-hover>
        <template #title>
          <h2 class="title_box">{{ $t('key1006116') }}</h2>
        </template>
        <pickingRules :pickingSetInfo="pickingRule" @updateRulesData="updateRulesData"></pickingRules>
      </Card>
    </div>
    <!--时间设置-->
    <div class="basic_information_box">
      <Card :bordered="false" dis-hover>
        <template #title>
          <h2 class="title_box">{{ $t('key1006117') }}</h2>
        </template>
        <Form>
          <Row type="flex" :gutter="gutterItem">
            <Col :span="ItemCol" class="basic_item">
              <FormItem :label="$t('key1006111')">
                <RadioGroup v-model="delayTime">
                  <Radio label="0">
                    <span>{{ $t('key1006118') }}</span>
                    <span>
                      {{ $t('key1006119') }}
                      <InputNumber size="small" v-model="fixedCycleTime"></InputNumber>
                      {{ $t('key1006120') }}
                    </span>
                  </Radio>
                  <br/>
                  <Radio label="1">
                    <span>{{ $t('key1006121') }}</span>
                    <span>
                      {{ $t('key1006122') }}
                    <TimePicker
                      format="HH:mm"
                      v-model="v.time"
                      placeholder=""
                      style="width: 80px;margin-right: 5px"
                      size="small"
                      v-for="(v, i) in addInputDataList"
                      :key="i">
                  </TimePicker>
                      <span @click="addTimePicker">
                        <Icon type="plus-circled" style="font-size: 18px"></Icon>
                      </span>
                      <span @click="dltTimePicker">
                        <Icon
                          type="minus-circled"
                          style="font-size: 18px"
                          v-if="addInputDataList.length > 1">
                        </Icon>
                      </span>
                      {{ $t('key1002562') }}
                    </span>
                  </Radio>
                </RadioGroup>
              </FormItem>
            </Col>
          </Row>
        </Form>
      </Card>
    </div>
    <!--是否开启-->
    <div class="basic_information_box">
      <Card :bordered="false" dis-hover>
        <template #title>
          <h2 class="title_box">{{ $t('key1006123') }}</h2>
        </template>
        <Form>
          <Row type="flex" :gutter="gutterItem">
            <Col :span="ItemCol" class="basic_item switch_box_style">
              <FormItem :label="$t('key1001634')">
                <i-switch v-model="singleRule">
                  <template #open>{{ $t('key1002316') }}</template>
                  <template #close>{{ $t('key1002317') }}</template>
                </i-switch>
              </FormItem>
            </Col>
          </Row>
        </Form>
      </Card>
    </div>
  </div>
</template>

<script>
import { alias47916751af154eb5b47cd5de9d34633d } from '@/customFolder/customVueAlias.js';

import Mixin from '@/components/mixin/common_mixin';
import pickingRules from "@/components/common/pickingRules";
import api from '@/api/';

export default {
  name: "pickingWave",
  mixins: [Mixin],
  data() {
    let v = this;
    return {
      ItemCol: 24,
      singleRuleStyle: {
        backgroundColor: 'rgba(228, 228, 228, 1)',
        width: '180px',
        height: '54px',
        lineHeight: '54px',
        textAlign: 'center'
      },
      mulRuleStyle: {
        backgroundColor: 'rgba(242, 242, 242, 1)',
        width: '180px',
        height: '54px',
        lineHeight: '54px',
        paddingLeft: '10px',
        textAlign: 'center'
      },
      delayTime: '0',
      showHideToggle: true,
      clickRuleType: 0,
      addInputDataList: [{time: ''}],
      fixedCycleTime: 1,
      singleRule: false,
      wareId: v.getWarehouseId(),
      pickingRule: {},
    };
  },
  computed: {
    // 获取当前登录用户的信息
    userInfo() {
      if (this.$store.state.erpConfig) {
        return this.$store.state.erpConfig.userInfo;
      } else {
        return '';
      }
    },
  },
  created() {
    this.singleRuleStyle.backgroundColor = 'rgba(228, 228, 228, 1)';
    this.mulRuleStyle.backgroundColor = 'rgba(242, 242, 242, 1)';
    this.queryRuleFn('1');
  },
  methods: {
    // 单品规则、多品规则按钮切换
    ruleClick(num) {
      // 单品规则
      if (num === 0) {
        this.singleRuleStyle.backgroundColor = 'rgba(228, 228, 228, 1)';
        this.mulRuleStyle.backgroundColor = 'rgba(242, 242, 242, 1)';
        this.queryRuleFn('1');
      }
      // 多品规则
      else {
        this.singleRuleStyle.backgroundColor = 'rgba(242, 242, 242, 1)';
        this.mulRuleStyle.backgroundColor = 'rgba(228, 228, 228, 1)';
        this.queryRuleFn('2');
      }
      if (num !== this.clickRuleType) {
        this.showHideToggle = false;
        setTimeout(() => {
          this.showHideToggle = true;
        }, 50);
      }
      this.clickRuleType = num;
      // 有异常
    },
    addTimePicker() {
      this.addInputDataList.push({time: ''});
    },
    dltTimePicker() {
      this.addInputDataList.pop();
    },

    timeTranslate(time, timeZoom) {
      // 按照时区处理时间
      let h = +time.split(':')[0];
      let m = time.split(':')[1];
      if (+timeZoom > 0) {
        if (h - +timeZoom >= 0) {
          return h - +timeZoom < 10 ? '0' + String(h - +timeZoom) + ':' + m : h - +timeZoom + ':' + m;
        } else {
          return 24 + h - +timeZoom + ':' + m;
        }
      } else {
        if (h - +timeZoom < 24) {
          return h - +timeZoom < 10 ? '0' + String(h - +timeZoom) + ':' + m : h - +timeZoom + ':' + m;
        } else {
          return h - +timeZoom - 24 + ':' + m;
        }
      }
    },

    backTime(time, timeZoom) {
      let h = +time.split(':')[0];
      let m = time.split(':')[1];
      if (h + +timeZoom > 24) {
        return h + +timeZoom - 24 + ':' + m;
      } else {
        return h + +timeZoom < 10 ? '0' + String(h + +timeZoom) + ':' + m : h + +timeZoom + ':' + m;
      }
    },

    // 规则保存
    saveRule() {
      let flag = true;
      let v = this;
      v.addInputDataList.forEach(val => {
        if (!val.time) {
          flag = false;
        }
      });
      if (v.delayTime === '1' && !flag) {
        v.$Message.warning(alias47916751af154eb5b47cd5de9d34633d.t('key1006124'));
        return;
      }
      let obj = {
        pickingType: v.clickRuleType === 0 && v.singleRule ? '1' : v.clickRuleType === 1 && v.singleRule
          ? '2'
          : v.clickRuleType === 0 && !v.singleRule
            ? '3'
            : v.clickRuleType === 1 && !v.singleRule
              ? '4'
              : '',
        ...v.pickingRule,
        hoursOrData: v.delayTime,
        createPickingGoodsTime: v.delayTime === '0'
          ? String(v.fixedCycleTime)
          : v.addInputDataList.map(val => v.timeTranslate(val.time, localStorage.getItem('timezoom'))).join(','),
        warehouseId: this.wareId
      };
      obj.allowDiffMailMode = v.pickingRule.allowDiffLogisticsDealer === '1' ? null : v.pickingRule.allowDiffMailMode;
      v.axios.post(api.post_wmsPickingGoods_createTask, obj, {loading: true, loadingText: alias47916751af154eb5b47cd5de9d34633d.t('key1000213')}).then(res => {
        if (res.data.code === 0) {
          v.$Message.success(res.data.message);
        }
      });
    },
    queryRuleFn(type) {
      if (this.getPermission('wmsPickingGoods_queryPickingGoodssupplier') || this.getPermission('wmsPicking_querySalesPicking_generateOrderList')) {
        return new Promise(resolve => {
          this.axios.get(api.get_wmsPickingGoods_queryPickingGoodsTask + '?warehouseId=' + this.wareId + '&pickingType=' + type).then(res => {
            if (res.data.code === 0) {
              if (res.data.datas) {
                var obj = JSON.parse(res.data.datas.taskContent);
              } else {
                this.singleRule = false;
              }
              if (obj) {
                if (obj.pickingType === 'pickingGoodsSingle') {
                  this.singleRule = true;
                } else if (obj.pickingType === 'pickingGoodsMany') {
                  this.singleRule = true;
                } else if (obj.pickingType === 'pickingGoodsSingleClose') {
                  this.singleRule = false;
                } else if (obj.pickingType === 'pickingGoodsManyClose') {
                  this.singleRule = false;
                }
                let keyList = [
                  'allowDiffLogisticsDealer',
                  'allowDiffMailMode',
                  'allowDiffWarehouseBlock',
                  'allowDiffWarehouseBlockGroup',
                  'maxPickingsNum'
                ];
                keyList.map((key) => {
                  this.pickingRule[key] = obj[key];
                });
                this.delayTime = obj.hoursOrData;
                if (obj.hoursOrData === '0') {
                  this.fixedCycleTime = Number(obj.createPickingGoodsTime);
                  this.addInputDataList = [{time: ''}];
                } else {
                  this.fixedCycleTime = null;
                  this.addInputDataList = obj.createPickingGoodsTime.split(',').map(val => ({
                    time: this.backTime(val, localStorage.getItem('timezoom'))
                  }));
                }
              } else {
                // 获取缓存中拣货单规则设置
                let userId = this.userInfo.userId;
                let pickListSetting = localStorage.getItem('pickListSetting' + userId);
                if (pickListSetting) {
                  this.pickingRule = JSON.parse(pickListSetting);
                }
              }
            }
            resolve();
          });
        });
      } else {
        // 无权限
        this.$router.push('/noPersermission');
      }
    },
    // 获取拣货单生成规则数据
    updateRulesData(data) {
      this.pickingRule = data;
    }
  },
  components: {
    pickingRules
  }
};
</script>

<style lang="less" scoped>
.basic_information_box {
  .tabs_box {
    padding: 17px 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    position: relative;

    .save_btn_style {
      position: absolute;
      right: 20px;
      bottom: 17px;
      z-index: 100;
    }
  }

  .switch_box_style {
    /deep/ .ivu-form-item {
      margin-bottom: 0;
      display: flex;
      align-items: center;
    }
  }
}
</style>
